import React, { createContext, ReactNode, useContext, useState } from 'react';
import { UserContextType } from '../../types';

type Props = {
  currentUser?: UserContextType;
  children: ReactNode;
};

const DEFAULT_VALUES: any = {
  id: '',
  name: '',
  email: '',
  admin: false,
  firstName: '',
  lastName: '',
  confirmedAt: false,
  initials: '',
  phoneCountryCode: '',
  phoneNumberVerifiedAt: null,
  phoneNumber: '',
  isUserLoggedIn: false,
};

export const UserContext = createContext({
  ...DEFAULT_VALUES,
  updateUserDetails: (_user: any) => {},
});

export const UserProvider = ({ currentUser, children }: Props) => {
  const [user, setUser] = useState(currentUser || DEFAULT_VALUES);

  const updateUserDetails = (newVal: UserContextType) => {
    setUser({
      ...user,
      ...newVal,
    });
  };

  const contextValue = {
    ...user,
    isUserLoggedIn: !!user.id,
    updateUserDetails,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
